import { css, keyframes } from '@emotion/react';
import { useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { useTickChanges } from '~/modules/SDK/ticktock/useTickChanges';
/** 商品進價提示 */
export const useVolumeUpdateAnimationCSS = (price = 0) => {
    const [updatedAnimation, setUpdatedAnimation] = useState();
    const prevPrice = useTickChanges(price);
    useUpdateEffect(() => {
        const quoteUpdateAnimationLight = keyframes `
      15%, 95% {
        border: 0.8px solid #78787877;
      }

      50% {
        border: 0.8px solid #787878cc;

      }
    `;
        setUpdatedAnimation(css `
      animation: ${quoteUpdateAnimationLight} 950ms ease-out 1;
      label: ${useVolumeUpdateAnimationCSS.name +
            '--' +
            (prevPrice.upThanPrevious ? 'priceUp' : 'priceDown')};
    `);
        const timerId = globalThis.setTimeout(() => {
            setUpdatedAnimation(null);
        }, 750);
        return () => {
            globalThis.clearTimeout(timerId);
        };
    }, [price]);
    return updatedAnimation;
};
