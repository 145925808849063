import styled from '@emotion/styled';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { useCrossKdPick } from '~/modules/investment-consultant/stockPickTable/stockPickTable';
import { SimpleQuoteListInteractWithChart } from '~/modules/symbolQuote/simple';
import { store } from '~/pages/heineken_template/_private/store';
import dayAPI from '~/utils/dayAPI';
import { boleStock_store } from '../_private/boleStock_store';
import { Select } from '@mantine/core';
import { CrossTypeEnum } from '~/modules/screener/containers/useStockScreenerResource';
import { range } from 'lodash';
import { css } from '@emotion/react';
import { VolumeFilter } from './VolumeFilter';
export const StockScreenerDaily = memo(function StockScreenerDaily() {
    const state = useSnapshot(boleStock_store);
    //const crossType = state.crossType
    const crossDay = state.crossDays;
    const crossTypeOptions = Object.entries(CrossTypeEnum).map(([value, label]) => ({
        value,
        label,
    }));
    const crossDaysOptions = Object.entries(range(1, 6, 1)).map(([value, label]) => ({
        value: String(label),
        label: String(label),
    }));
    // 選股死亡交叉
    const deathList = useCrossKdPick({
        date: dayAPI().format('YYYY-MM-DD'),
        cross_type: 'death',
        days: crossDay,
        fastK: 44,
        slowD: 4,
        slowK: 3,
    });
    // 選股黃金交叉
    const goldenList = useCrossKdPick({
        date: dayAPI().format('YYYY-MM-DD'),
        cross_type: 'golden',
        days: crossDay,
        fastK: 44,
        slowD: 4,
        slowK: 3,
    });
    const data = state.crossType === 'golden' ? goldenList : deathList;
    return (<styleds.container>
      <styleds.optionContent>
        <span>選股條件</span>
        <Select css={css `
            .mantine-58jpum {
              background-color: #414141;
              height: 38px;
            }
          `} defaultValue={crossTypeOptions.map(s => s.value)[0]} data={crossTypeOptions} onChange={value => (boleStock_store.crossType = value)}/>
        <span>天數選擇</span>
        <Select css={css `
            .mantine-58jpum {
              background-color: #414141;
              height: 38px;
            }
          `} defaultValue={crossDaysOptions.map(s => s.value)[0]} data={crossDaysOptions} onChange={value => (boleStock_store.crossDays = Number(value))}/>
      </styleds.optionContent>
      <styleds.symbolListContent>
        <SimpleQuoteListInteractWithChart data={data ?? ['']} chart={store.charting} handleClick={event => {
            boleStock_store.userLastFocusSymbol = event;
            store.charting.changeInterval('1D');
        }}/>
      </styleds.symbolListContent>
      <VolumeFilter />
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    display: grid;
    grid-template-rows: 48px calc(100% - 92px) 42px;
    gap: 2px;
  `,
    optionContent: styled.div `
    display: grid;
    grid-template-columns: calc(20% - 4px) calc(30% - 4px) calc(20% - 4px) calc(30% - 4px);
    grid-gap: 4px;
    height: 100%;
    background-color: #222222;
    border-radius: 4px;
    font-size: 14px;
    & > * {
      ${fill_horizontal_all_center};
    }
  `,
    symbolListContent: styled.div `
    ${fill_vertical_cross_center};
  `,
};
