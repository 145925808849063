import styled from '@emotion/styled';
import { memo } from 'react';
import { fill_vertical_cross_center } from '~/modules/AppLayout/FlexGridCss';
import { WatchListWithChart } from '~/modules/symbolQuote/simple';
import { store } from '~/pages/heineken_template/_private/store';
import { boleStock_store } from '../_private/boleStock_store';
export const SymbolWatchList = memo(function SymbolWatchList() {
    return (<styleds.container>
      <WatchListWithChart groupName={'bole_stock_group_1'} useChart={store.charting} handleClick={event => {
            boleStock_store.userLastFocusSymbol = event;
        }}/>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center}
  `,
};
