import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const bole_stock = createIndicator({
    displayName: '伯樂',
    id: 'bole-stock-trade',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const f_0 = (e) => {
                return this.PineJS.Std.max(e, 0);
            };
            const f_1 = (e) => {
                return -this.PineJS.Std.min(e, 0);
            };
            const f_2 = (e, t) => {
                return this.PineJS.Std.eq(e, 0)
                    ? 100
                    : this.PineJS.Std.eq(t, 0)
                        ? 0
                        : 100 - 100 / (1 + t / e);
            };
            const close = this.PineJS.Std.close(this._context);
            const i = this.PineJS.Std.close(this._context);
            const r = 12;
            const o = this._context.new_var(i);
            const s = this.PineJS.Std.change(o);
            const a = f_0(s);
            const l = this._context.new_var(a);
            const c = this.PineJS.Std.rma(l, r, this._context);
            const u = f_1(s);
            const h = this._context.new_var(u);
            const d = this.PineJS.Std.rma(h, r, this._context);
            const in0 = 44;
            const in1 = 4;
            const in2 = 3;
            const high = this.PineJS.Std.high(this._context);
            const low = this.PineJS.Std.low(this._context);
            const high_array = this._context.new_var(high);
            const low_array = this._context.new_var(low);
            //高
            const vHn = this.PineJS.Std.highest(high_array, in0, this._context);
            //低
            const vLn = this.PineJS.Std.lowest(low_array, in0, this._context);
            //RSV
            const vrsv = ((close - vLn) / (vHn - vLn)) * 100;
            //K%
            const vk_array = this._context.new_var();
            const ss = (1.0 / in1) * vrsv + (1 - 1.0 / in1) * vk_array.get(1);
            if (isNaN(ss)) {
                vk_array.set(0);
            }
            else {
                vk_array.set(ss);
            }
            //D%
            const vd_array = this._context.new_var();
            const qq = (1.0 / in2) * vk_array + (1 - 1.0 / in2) * vd_array.get(1);
            if (isNaN(qq)) {
                vd_array.set(0);
            }
            else {
                vd_array.set(qq);
            }
            const long1 = vk_array.get(0) > vd_array.get(0);
            const long2 = f_2(d, c) - 50 > 0;
            let buy_icon = NaN;
            const marketposition = this._context.new_var();
            marketposition.get(1);
            if (long1 && long2 && marketposition.get(0) !== 1) {
                marketposition.set(1);
                buy_icon = 1;
            }
            const exit1 = this._context.new_var();
            const exit2 = this._context.new_var();
            if (!long1) {
                exit1.set(1);
            }
            if (!long2) {
                exit2.set(1);
            }
            if (exit1.get(0) === 1 && exit2.get(0) === 1) {
                exit1.set(0);
                exit2.set(0);
                marketposition.set(0);
            }
            return [buy_icon];
        },
    },
    metainfo: {
        _metainfoVersion: 40,
        scriptIdPart: '',
        is_hidden_study: false,
        is_price_study: true,
        isCustomIndicator: true,
        plots: [
            {
                id: 'plot_0',
                type: 'chars',
            },
        ],
        defaults: {
            styles: {
                plot_0: {
                    color: '#dd5e56',
                    textColor: '#dd5e56',
                    transparency: 0,
                    visible: true,
                },
            },
        },
        palettes: {},
        styles: {
            plot_0: {
                isHidden: false,
                location: 'BelowBar',
                char: '▲',
                size: 'small',
                text: '多方',
                title: '參考',
            },
        },
        inputs: [],
    },
});
