import styled from '@emotion/styled';
import { memo } from 'react';
import { AppLink2 } from '~/components/AppLink2';
import { fill_vertical_all_center } from '~/modules/AppLayout/FlexGridCss';
export const UnavailableView = memo(function UnavailableView() {
    return (<classes.container>
      <span>請使用桌機版網頁使用</span>

      <AppLink2 href={'/bole_stock'}>
        <classes.link>返回首頁</classes.link>
      </AppLink2>
    </classes.container>);
});
const classes = {
    container: styled.div `
    ${fill_vertical_all_center};
    font-size: 14px;
  `,
    link: styled.span `
    color: #ffffff;
    font-weight: bold;
  `,
};
