import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button } from '@mantine/core';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { fill_horizontal_cross_center, jc } from '~/modules/AppLayout/FlexGridCss';
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch';
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton';
import { fr_instrument } from '~/modules/SDK/FrInstrument/FrInstrument';
import { store } from '~/pages/heineken_template/_private/store';
import useMedia from '~/hooks/useMedia';
import { Preset_Topbar } from '~/pages/heineken_template/_preset/preset_Topbar';
const SymbolButton = memo(function SymbolButton(props) {
    const selected = props.symbol === useSnapshot(store).charting.symbol;
    const symbolName = fr_instrument.getName(props.symbol);
    return (<Button css={css `
        width: 132px;
        height: 32px;
      `} onClick={() => store.charting.changeSymbol(props.symbol)} variant={selected ? 'filled' : 'default'}>
      {props.symbol}
      {symbolName}
    </Button>);
});
export const BoleStock_Topbar = memo(function BoleStock_Topbar(props) {
    const { isPc } = useMedia();
    return (<styleds.container>
        {isPc && (<styleds.topbarContent>
            <SymbolButton symbol='TSEA'/>
            <SymbolButton symbol='OTCA'/>
            <SymbolButton symbol='TX-1'/>
            <SymbolButton symbol='TXAM-1'/>
          </styleds.topbarContent>)}
        {isPc && (<styleds.topbarContent>
            <ChartingServerSwitch charting={store.charting}/>
            <UserAvatarAsDialogButton />
          </styleds.topbarContent>)}
        {!isPc && (<Preset_Topbar showRightBurger={props.mainPage ? true : false} showLeftBurger={true} componentsInRight={<div css={css `
                  ${fill_horizontal_cross_center};
                  justify-content: end;
                `}>
                <ChartingServerSwitch charting={store.charting}/>
                <UserAvatarAsDialogButton />
              </div>}/>)}
      </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_horizontal_cross_center};
    ${jc.spaceBetween};
    padding: 0px 4px;
    background-color: #222222;
  `,
    topbarContent: styled.div `
    ${fill_horizontal_cross_center};
    width: auto;
    gap: 6px;
  `,
};
